import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/ncc-welcome' },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule) },
  { path: 'register', loadChildren: () => import('./register/register.module').then(m => m.RegisterPageModule) },
  { path: 'update-profile', loadChildren: () => import('./update-profile/update-profile.module').then(m => m.UpdateProfilePageModule) },
  { path: 'otp-profile', loadChildren: () => import('./otp-profile/otp-profile.module').then(m => m.OtpProfilePageModule) },
  { path: 'otp-phone-verify', loadChildren: () => import('./otp-phone-verify/otp-phone-verify.module').then(m => m.OtpPhoneVerifyPageModule) },
  { path: 'ncc-compatible', loadChildren: () => import('./ncc-compatible/ncc-compatible.module').then(m => m.NccCompatiblePageModule) },
  { path: 'ncc-access-denied', loadChildren: () => import('./ncc-access-denied/ncc-access-denied.module').then(m => m.NccAccessDeniedPageModule) },
  { path: 'otp-success', loadChildren: () => import('./otp-success/otp-success.module').then(m => m.OtpSuccessPageModule) },
  { path: 'profile', loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule) },
  { path: 'ncc-pdpa', loadChildren: () => import('./ncc-pdpa/ncc-pdpa.module').then(m => m.NccPdpaPageModule) },
  { path: 'ncc-faceid', loadChildren: () => import('./ncc-faceid/ncc-faceid.module').then(m => m.NccFaceidPageModule) },
  { path: 'ncc-register-complete', loadChildren: () => import('./ncc-register-complete/ncc-register-complete.module').then(m => m.NccRegisterCompletePageModule) },
  { path: 'ncc-welcome', loadChildren: () => import('./ncc-welcome/ncc-welcome.module').then(m => m.NccWelcomePageModule) },
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  { path: 'ncc-reset-pin', loadChildren: () => import('./ncc-reset-pin/ncc-reset-pin.module').then(m => m.NccResetPinPageModule) },
  { path: 'reset-pin', loadChildren: () => import('./reset-pin/reset-pin.module').then(m => m.ResetPinPageModule) },
  { path: 'update-success', loadChildren: () => import('./update-success/update-success.module').then(m => m.UpdateSuccessPageModule) },
  { path: 'create-account', loadChildren: () => import('./create-account/create-account.module').then(m => m.CreateAccountPageModule) },
  { path: 'create-profile', loadChildren: () => import('./create-profile/create-profile.module').then(m => m.CreateProfilePageModule) },
  { path: 'create-pin', loadChildren: () => import('./create-pin/create-pin.module').then(m => m.CreatePinPageModule) },
  { path: 'ncc-date-birth', loadChildren: () => import('./ncc-date-birth/ncc-date-birth.module').then(m => m.NccDateBirthPageModule) },
  { path: 'ncc-invalid-pin', loadChildren: () => import('./ncc-invalid-pin/ncc-invalid-pin.module').then(m => m.NccInvalidPinPageModule) },
  { path: 'create-pin-update', loadChildren: () => import('./create-pin-update/create-pin-update.module').then(m => m.CreatePinUpdatePageModule) },
  { path: 'ncc-view-profile', loadChildren: () => import('./ncc-view-profile/ncc-view-profile.module').then(m => m.NccViewProfilePageModule) },

  { path: 'home-church-life', loadChildren: () => import('./home-church-life/home-church-life.module').then(m => m.HomeChurchLifePageModule) },
  { path: 'ncc-duplicate-records', loadChildren: () => import('./ncc-duplicate-records/ncc-duplicate-records.module').then(m => m.NccDuplicateRecordsPageModule) },
  { path: 'ncc-server-busy', loadChildren: () => import('./ncc-server-busy/ncc-server-busy.module').then(m => m.NccServerBusyPageModule) },
  { path: 'home-login', loadChildren: () => import('./home-login/home-login.module').then(m => m.HomeLoginPageModule) },
  { path: 'ncc-welcome-home', loadChildren: () => import('./ncc-welcome-home/ncc-welcome-home.module').then(m => m.NccWelcomeHomePageModule) },
  { path: 'rock-kidz', loadChildren: () => import('./rock-kidz/rock-kidz.module').then(m => m.RockKidzPageModule) },
  { path: 'register-child', loadChildren: () => import('./rock-kidz/register-child/register-child.module').then(m => m.RegisterChildPageModule) },
  { path: 'register-relationship', loadChildren: () => import('./rock-kidz/register-relationship/register-relationship.module').then(m => m.RegisterRelationshipPageModule) },
  { path: 'emergency-contacts', loadChildren: () => import('./rock-kidz/emergency-contacts/emergency-contacts.module').then(m => m.EmergencyContactsPageModule) },
  { path: 'notification', loadChildren: () => import('./rock-kidz/notification/notification.module').then(m => m.NotificationPageModule) },
  { path: 'update-emergency-contact', loadChildren: () => import('./rock-kidz/update-emergency-contact/update-emergency-contact.module').then(m => m.UpdateEmergencyContactPageModule) },
  { path: 'add-emergency-contact', loadChildren: () => import('./rock-kidz/add-emergency-contact/add-emergency-contact.module').then(m => m.AddEmergencyContactPageModule) },
  { path: 'term-conditions', loadChildren: () => import('./rock-kidz/term-conditions/term-conditions.module').then(m => m.TermConditionsPageModule) },
  { path: 'registration-received', loadChildren: () => import('./rock-kidz/registration-received/registration-received.module').then(m => m.RegistrationReceivedPageModule) },
  { path: 'deactivate-account', loadChildren: () => import('./deactivate-account/deactivate-account.module').then(m => m.DeactivateAccountModule) },
  { path: 'deactivate-confirm', loadChildren: () => import('./deactivated-confirm/deactivated-confirm.module').then(m => m.DeactivatedConfirmModule) },
  { path: 'emergency-contact', loadChildren: () => import('./emergency-contact/emergency-contact.module').then(m => m.EmergencyContactModule) },

  { path: 'emergency-contact-confirm', loadChildren: () => import('./emergency-contact-confirm/emergency-contact-confirm.module').then(m => m.EmergencyContactConfirmModule) },
  { path: 'add-family', loadChildren: () => import('./ncc-view-profile/add-family/add-family.module').then(m => m.AddFamilyPageModule) },
  { path: 'relationship-request', loadChildren: () => import('./ncc-view-profile/relationship-request/relationship-request.module').then(m => m.RelationshipRequestPageModule) },
  { path: 'family-request', loadChildren: () => import('./ncc-view-profile/family-request/family-request.module').then(m => m.FamilyRequestPageModule) },
  { path: 'respond-to-request', loadChildren: () => import('./ncc-view-profile/respond-to-request/respond-to-request.module').then(m => m.RespondToRequestPageModule) },
  { path: 'edit-family', loadChildren: () => import('./ncc-view-profile/edit-family/edit-family.module').then(m => m.EditFamilyPageModule) },
  { path: 'delete-success', loadChildren: () => import('./ncc-view-profile/delete-success/delete-success.module').then(m => m.DeleteSuccessPageModule) },
  { path: 'change-mobile', loadChildren: () => import('./ncc-view-profile/change-mobile/change-mobile.module').then(m => m.ChangeMobilePageModule) },
  { path: 'change-mobile-warning', loadChildren: () => import('./ncc-view-profile/change-mobile-warning/change-mobile-warning.module').then(m => m.ChangeMobileWarningPageModule) },
  { path: 'update-mobile', loadChildren: () => import('./ncc-view-profile/update-mobile/update-mobile.module').then(m => m.UpdateMobilePageModule) },
  { path: 'mobile-exists', loadChildren: () => import('./ncc-view-profile/mobile-exists/mobile-exists.module').then(m => m.MobileExistsPageModule) },
  { path: 'rockkidz-pdpa', loadChildren: () => import('./rock-kidz/rockkidz-pdpa/rockkidz-pdpa.module').then(m => m.RockkidzPdpaPageModule) },
  { path: 'view-emergency-contact', loadChildren: () => import('./rock-kidz/view-emergency-contact/view-emergency-contact.module').then(m => m.ViewEmergencyContactPageModule) },
  { path: 'delete-emergency-contact', loadChildren: () => import('./rock-kidz/delete-emergency-contact/delete-emergency-contact.module').then(m => m.DeleteEmergencyContactPageModule) },
  { path: 'edit-emergency-contact', loadChildren: () => import('./rock-kidz/edit-emergency-contact/edit-emergency-contact.module').then(m => m.EditEmergencyContactPageModule) },
  { path: 'emergency-reminder', loadChildren: () => import('./rock-kidz/emergency-reminder/emergency-reminder.module').then(m => m.EmergencyReminderPageModule) },

  { path: 'rockkidz-view-profile', loadChildren: () => import('./rock-kidz-view-profile/rock-kidz-view-profile.module').then(m => m.RockkidzViewProfilePageModule) },
  { path: 'update-change-mobile', loadChildren: () => import('./ncc-view-profile/update-change-mobile/update-change-mobile.module').then(m => m.UpdateChangeMobilePageModule) },
  { path: 'update-rock-kidz-success', loadChildren: () => import('./update-rock-kidz-success/update-rock-kidz-success.module').then(m => m.UpdateRockKidzSuccessPageModule) },
  { path: 'update-rkaddress-success', loadChildren: () => import('./update-rkaddress-success/update-rkaddress-success.module').then(m => m.UpdateRkaddressSuccessPageModule) },
  { path: 'deactivate-message', loadChildren: () => import('./deactivate-message/deactivate-message.module').then(m => m.DeactivateMessagePageModule) },
  { path: 'deactivated-confirm-sms', loadChildren: () => import('./deactivated-confirm-sms/deactivated-confirm-sms.module').then(m => m.DeactivatedConfirmSmsPageModule) },
  { path: 'deactivated-no-found', loadChildren: () => import('./deactivated-no-found/deactivated-no-found.module').then(m => m.DeactivatedNoFoundPageModule) },
  { path: 'deactivated-uncomplete', loadChildren: () => import('./deactivated-uncomplete/deactivated-uncomplete.module').then(m => m.DeactivatedUncompletePageModule) },
  { path: 'update-changed-emergency', loadChildren: () => import('./update-changed-emergency/update-changed-emergency.module').then(m => m.UpdateChangedEmergencyPageModule) },
  { path: 'add-emergency', loadChildren: () => import('./add-emergency/add-emergency.module').then(m => m.AddEmergencyPageModule) },
  { path: 'view-emergency', loadChildren: () => import('./view-emergency/view-emergency.module').then(m => m.ViewEmergencyPageModule) },
  { path: 'popup-comfirm-emergency', loadChildren: () => import('./popup-comfirm-emergency/popup-comfirm-emergency.module').then(m => m.PopupComfirmEmergencyPageModule) },
  { path: 'popup-self-emergency', loadChildren: () => import('./popup-self-emergency/popup-self-emergency.module').then(m => m.PopupSelfEmergencyPageModule) },
  { path: 'nominate-emergency-contact', loadChildren: () => import('./nominate-emergency-contact/nominate-emergency-contact.module').then(m => m.NominateEmergencyContactPageModule) },
  { path: 'profile-inactive', loadChildren: () => import('./profile-inactive/profile-inactive.module').then(m => m.ProfileInactivePageModule) },
  { path: 'create-user-success', loadChildren: () => import('./create-user-success/create-user-success.module').then(m => m.CreateUserSuccessPageModule) },
  { path: 'delete-account-message', loadChildren: () => import('./delete-account-message/delete-account-message.module').then(m => m.DeleteAccountMessagePageModule) },
  { path: 'update-rockkidz-address', loadChildren: () => import('./update-rockkidz-address/update-rockkidz-address.module').then(m => m.UpdateRockkidzAddressPageModule) },
  { path: 'reset-pin-success', loadChildren: () => import('./reset-pin-success/reset-pin-success.module').then(m => m.ResetPinSuccessPageModule) },
  { path: 'error-login', loadChildren: () => import('./error-login/error-login.module').then(m => m.ErrorLoginPageModule) },
  { path: 'new-emergency-contact', loadChildren: () => import('./rock-kidz-view-profile/new-emergency-contact/new-emergency-contact.module').then(m => m.NewEmergencyContactPageModule) },
  { path: 'emergency-contact-non-reorder', loadChildren: () => import('./rock-kidz-view-profile/emergency-contact-non-reorder/emergency-contact-non-reorder.module').then(m => m.EmergencyContactNonReorderPageModule) },
  { path: 'emergency-contact-detail-non-reorder', loadChildren: () => import('./view-emergency/emergency-contact-detail-non-reorder/emergency-contact-detail-non-reorder.module').then(m => m.EmergencyContactDetailNonReorderPageModule) },
  { path: 'rockkidz-registration-info', loadChildren: () => import('./rock-kidz/rockkidz-registration-info/rockkidz-registration-info.module').then(m => m.RockkidzRegistrationInfoPageModule) },

  { path: 'rk-promotion-message', loadChildren: () => import('./rk-promotion-message/rk-promotion-message.module').then(m => m.RkPromotionMessageModule) },
  { path: 'rk-promotion-update-profile', loadChildren: () => import('./rk-promotion-update-profile/rk-promotion-update-profile.module').then(m => m.RkPromotionUpdateProfileModule)},
  { path: 'rk-promotion-success', loadChildren: () => import('./rk-promotion-success/rk-promotion-success.module').then(m => m.RkPromotionSuccessModule)},
  { path: 'rk-promotion-in-progress', loadChildren: () => import('./rk-promotion-in-progress/rk-promotion-in-progress.module').then(m => m.RkPromotionInProgressModule)},

  { path: 'error', loadChildren: () => import('./error/error.module').then(m => m.ErrorPageModule) },
  { path: 'profile-exists', loadChildren: () => import('./profile-exists/profile-exists.module').then(m => m.ProfileExistsPageModule) },
  { path: 'ncc-create-account', loadChildren: () => import('./ncc-create-account/ncc-create-account.module').then(m => m.NccCreateAccountModule) },
  { path: 'ncc-invalid-account', loadChildren: () => import('./invalid-account/invalid-account.module').then(m => m.InvalidAccountPageModule) },
  {
    path: 'ga',
    loadChildren: () => import('./ga/ga.module').then( m => m.GaPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}