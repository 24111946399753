import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable()
export class SSOTokenService {
  constructor(private httpClient: HttpClient) { }

  generateAccessToken(): Promise<any> {
    const client_id = environment.hubClientId;
    const client_secret = environment.hubClientSecret;
    const scopes = environment.hubScopes;
    const params = new HttpParams({
      fromObject: { client_id, client_secret, scopes }
    });
    return this.httpClient.post(`${environment.authApiUrl}/authentication/token`, params, {}).toPromise();
  }

  validateAccessToken(access_token): Promise<any> {
    const params = new HttpParams({
      fromObject: { access_token }
    });
    return this.httpClient.post(`${environment.authApiUrl}/authentication/validate`, params, {}).toPromise();
  }

  validateTokens(): Promise<any> {
    const accessToken = localStorage.getItem('user.accessToken');
    const params = new HttpParams({
      fromObject: { accessToken }
    });

    return this.httpClient.post(`${environment.authApiUrl}/authentication/validateTokens`, params, {}).toPromise();
  }
}