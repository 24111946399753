import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NavigationEnd, Router } from '@angular/router';

import { DeviceDetectorService } from 'ngx-device-detector';
import { NccWelcomeService } from './ncc-welcome/ncc-welcome.service';
import { HubBaseComponent } from './base/hub-base-component';
import { HandleUnrecoverableStateService } from 'src/common-services/handle-unrecoverable-state.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    providers: [DeviceDetectorService, NccWelcomeService, HandleUnrecoverableStateService]
})
export class AppComponent extends HubBaseComponent {
    deviceInfo = null;
    constructor(
        private deviceService: DeviceDetectorService,
        private nccWelcomeService: NccWelcomeService,
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private router: Router,
        private handleUnrecoverableStateService: HandleUnrecoverableStateService
    ) {
        super();
        this.initializeApp();
        this.handleUnrecoverableStateService.subscribeToUnrecoverableState();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
            this.epicFunction();

            this.router.events.subscribe(event => {
                if (event instanceof NavigationEnd 
                    && (event.urlAfterRedirects == '/home' 
                        || event.urlAfterRedirects == '/ncc-welcome'
                        || event.urlAfterRedirects.startsWith('/error'))){
                        this.getAllResources();
                        this.getRockKidzResources();
                }
            });
        });
    }

    epicFunction() {
        this.deviceInfo = this.deviceService.getDeviceInfo();
        if (this.deviceInfo.userAgent.indexOf('MSIE') > 0 ||
            this.deviceInfo.userAgent.indexOf('Firefox/4') != -1 ||
            this.deviceInfo.userAgent.indexOf('Firefox/3') != -1 ||
            this.deviceInfo.userAgent.indexOf('Firefox/2') != -1 ||
            this.deviceInfo.userAgent.indexOf('Safari/3.') != -1 ||
            this.deviceInfo.userAgent.indexOf('Safari/11') != -1 ||
            this.deviceInfo.userAgent.indexOf('OPR/1') != -1 ||
            this.deviceInfo.userAgent.indexOf('Chrome/3') != -1 ||
            this.deviceInfo.userAgent.indexOf('Chrome/2') != -1 ||
            //this.deviceInfo.userAgent.indexOf('Chrome/1') != -1 ||
            this.deviceInfo.userAgent.indexOf('EdgiOS/1') != -1 ||
            this.deviceInfo.userAgent.indexOf('EdgA/1') != -1 ||
            this.deviceInfo.userAgent.indexOf('Opera Mini') != -1 ||
            this.deviceInfo.userAgent.indexOf('BlackBerry') != -1) {
            this.router.navigateByUrl('/ncc-compatible');

        }
        const isMobile = this.deviceService.isMobile();
        const isTablet = this.deviceService.isTablet();
        const isDesktopDevice = this.deviceService.isDesktop();
    }

    private getAllResources() : void {
        this.nccWelcomeService.getAllResources().then(data => {
            const {
                countries,
                countryCodes,
                maritalStatuses,
                nationalities,
                races,
                relationshipStatuses,
                relationships,
                userStatuses,
                guardians,
                partnerRelationMapping,
                genderOfRelation,
            } = data;
            localStorage.setItem('countries', JSON.stringify(countries));
            localStorage.setItem('countryCodes', JSON.stringify(countryCodes));
            localStorage.setItem('maritalStatuses', JSON.stringify(maritalStatuses));
            localStorage.setItem('nationalities', JSON.stringify(nationalities));
            localStorage.setItem('races', JSON.stringify(races));
            localStorage.setItem('relationshipStatuses', JSON.stringify(relationshipStatuses));
            localStorage.setItem('relationships', JSON.stringify(relationships));
            localStorage.setItem('userStatuses', JSON.stringify(userStatuses));
            localStorage.setItem('guardians', JSON.stringify(guardians));
            localStorage.setItem('partnerRelationMapping', JSON.stringify(partnerRelationMapping));
            localStorage.setItem('genderOfRelation', JSON.stringify(genderOfRelation));
        }).catch(e => {
            this.captureException(e);
        });
    }

    private getRockKidzResources() : void {
        this.nccWelcomeService.getRockKidzResources().then((RKRefernceResult) => {
            const {
                medicalConditions,
                rockKidzClasses,
                rockKidzUserStatuses,
                emergencyContactStatuses,
            } = RKRefernceResult;
            localStorage.setItem('rockKidzMedicalConditions', JSON.stringify(medicalConditions));
            localStorage.setItem('rockKidzClasses', JSON.stringify(rockKidzClasses));
            localStorage.setItem('rockKidzUserStatuses', JSON.stringify(rockKidzUserStatuses));
            localStorage.setItem('emergencyContactStatuses', JSON.stringify(emergencyContactStatuses));
        }).catch((err) => {
            this.captureException(err);
        });
    }
}