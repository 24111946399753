import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

    constructor() {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const apikey = localStorage?.getItem('user.apikey');
        if (apikey) {
          request = request.clone({
            headers: request.headers
              .set('Content-Type', 'application/x-www-form-urlencoded')
              .set('Authorization', `api-key ${apikey}`),
          });
        } else {
          request = request.clone({
            headers: request.headers
              .set('Content-Type', 'application/x-www-form-urlencoded')
          });
        }
        request = request.clone({ withCredentials: true });
        return next.handle(request).pipe(
            tap({
                next: event => {
                    if (event instanceof HttpResponse) {
                    }
                },
                error: error => {
                    if (error instanceof HttpErrorResponse) {
                        if (error.status === 401) {
                        }
                    }
                },
            })
        )
    }
}