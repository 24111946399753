import * as Sentry from '@sentry/angular';
import { HttpErrorResponse } from '@angular/common/http';

export class HubBaseComponent {
    
    // Capture the exception and send the detail to Sentry based on the exception type
    captureException(exception: any): string {
        let sentryEventId : string = '';
        if (exception instanceof HttpErrorResponse){
            if (exception.error instanceof Error) {
                sentryEventId = Sentry.captureException(exception.error);
                return sentryEventId;
            }
            
            if (exception.error instanceof ErrorEvent) {
                sentryEventId = Sentry.captureException(exception.error.message);
                return sentryEventId;
            }

            if (typeof exception.error === 'string') {
                sentryEventId = Sentry.captureException(`Server returned code ${exception.status} with body "${exception.error}"`);
                return sentryEventId;
            }

            sentryEventId = Sentry.captureException(exception.message);
            return sentryEventId;
        }

        sentryEventId = Sentry.captureException(exception.originalError || exception.error || exception);
        return sentryEventId;
    }
}
