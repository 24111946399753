import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, map } from 'rxjs/operators';
import { environment } from '../environments/environment';
@Injectable({ providedIn: 'root' })
export class HandleUnrecoverableStateService {
  constructor(private swUpdate: SwUpdate) {
    // this.subscribeToUnrecoverableState();
  }

  public subscribeToUnrecoverableState() {
    //HUB-813: subscribe only if env is production or staging
    if (this.swUpdate?.isEnabled && (environment.production || environment.staging)) {
      //HUB-813: show alert and force user reload page...
      this.swUpdate.versionUpdates
        .pipe(
          filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')
        )
        .subscribe(() => {
          alert("Application update in progress...");
          window.location.reload();
        });
    }
  }
}