import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { CommonModule } from '@angular/common';
// import { HeaderPageModule } from './header/header.module';
// import { FooterPageModule } from './footer/footer.module';
// import { CoppyRightModule } from './coppyright/coppyright.module';
// import { FieldErrorDisplayModule } from './field-error-display/field-error-display.module';
import { Header } from './header/header';
import { Footer } from './footer/footer';
import { CoppyRight } from './coppyright/coppyright';
import { PopupConfirm } from './popup-confirm/popup-confirm';
import { FieldErrorDisplay } from './field-error-display/field-error-display';
import { ErrorModalComponent } from './error-modal/error-modal.component';
//import { ProfileDetailsPage } from '../../ncc-view-profile/profile-details/profile-details';
  

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
  ],
  declarations: [
      Header,
      Footer,
      CoppyRight,
      PopupConfirm,
      FieldErrorDisplay,
      ErrorModalComponent
  ],
  exports: [
      Header,
      Footer,
      CoppyRight,
      PopupConfirm,
      FieldErrorDisplay,
      ErrorModalComponent
  ]
})
export class SharedModule {}
