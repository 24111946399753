import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class NccWelcomeService {
    constructor(private httpClient: HttpClient) {}

    otpValidatePhone(countryCode, phoneNumber): Promise<any> {
        const params = new HttpParams({
            fromObject: { countryCode, phoneNumber }
        });

        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'validate_type': 'login'
          });
        let options = { headers: headers };

        return this.httpClient.post(`${environment.apiUrl}/user/validate-phone`,
            params, options).toPromise();
    }

    getCountryCodeFromNoah(): Promise<any> {
        return this.httpClient.get(`${environment.apiUrl}/user/get-noah-country-code`, {}).toPromise();
    }

    getAllResources(): Promise<any> {
        return this.httpClient.get(`${environment.apiUrl}/user/allResources`, {}).toPromise();
    }

    getRockKidzResources(): Promise<any> {
        return this.httpClient.get(`${environment.rockKidzApiUrl}/rockKidz/getRockKidzReference`, { }).toPromise();
    }
}