import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { lastValueFrom } from 'rxjs';

// Import user reference models
import { CountryCode } from 'models/reference/country-code';
import { Country } from 'models/reference/country';
import { MaritalStatus } from 'models/reference/marital-status';
import { Nationality } from 'models/reference/nationality';
import { Race } from 'models/reference/race';
import { RelationshipStatus } from 'models/reference/relationship-status';
import { Relationship } from 'models/reference/relationship';
import { UserStatus } from 'models/reference/user-status';
import { MedicalCondition } from 'models/reference/medical-condition';
import { RockKidzClass } from 'models/reference/rockkidz-class';
import { RockKidzUserStatus } from 'models/reference/rockkidz-user-status';
import { EmergencyContactStatus } from 'models/reference/emergency-contact-status';

@Injectable({
    providedIn: 'root'
})
export class ReferenceService {
    private countryCodes : CountryCode[] = [];
    private countries : Country[] = [];
    private maritalStatuses : MaritalStatus[] = [];
    private nationalities : Nationality[] = [];
    private races : Race[] = [];
    private relationGenders : Object = {};
    private relationshipStatuses : RelationshipStatus[] = [];
    private relationships : Relationship[] = [];
    private userStatuses : UserStatus[] = [];
    private guardians : string[] = [];
    private medicalConditions : MedicalCondition [] = [];
    private rockkidzClasses : RockKidzClass [] = [];
    private rockKidzUserStatuses : RockKidzUserStatus [] = [];
    private emergencyContactStatuses : EmergencyContactStatus [] = [];

    constructor(
        private httpClient: HttpClient
    ) { 
    }

    private async getUserReferences(){
        return await lastValueFrom(this.httpClient.get(`${environment.apiUrl}/user/allResources`));
    }

    private async getRockKidzReferences(): Promise<any> {
        return await lastValueFrom (this.httpClient.get(`${environment.rockKidzApiUrl}/rockKidz/getRockKidzReference`, { }));
    }
    
    public async loadServiceData(){
        const userReferenceResponse: any = await this.getUserReferences();

        if (userReferenceResponse) {
            this.countries = userReferenceResponse.countries;
            this.countryCodes = userReferenceResponse.countryCodes;
            this.nationalities = userReferenceResponse.nationalities;
            this.races = userReferenceResponse.races;
            this.maritalStatuses = userReferenceResponse.maritalStatuses;
            this.userStatuses = userReferenceResponse.userStatuses;
            this.relationships = userReferenceResponse.relationships;
            this.relationshipStatuses = userReferenceResponse.relationshipStatuses;
            this.relationGenders = userReferenceResponse.genderOfRelation;
            this.guardians = userReferenceResponse.guardians;
        }

        const rockkidzReferenceResponse: any = await this.getRockKidzReferences();
        if (rockkidzReferenceResponse) {
            this.medicalConditions = rockkidzReferenceResponse.medicalConditions;
            this.rockkidzClasses = rockkidzReferenceResponse.rockKidzClasses
            this.rockKidzUserStatuses = rockkidzReferenceResponse.rockKidzUserStatuses;
            this.emergencyContactStatuses = rockkidzReferenceResponse.emergencyContactStatuses;
        }
    }

    getReferenceTypes(){
        return ReferenceTypes;
    }

    getReference(type : ReferenceTypes): any {
        switch (type) {
            case ReferenceTypes.country:
                return this.countries;

            case ReferenceTypes.countryCode:
                return this.countryCodes;

            case ReferenceTypes.nationality:
                return this.nationalities;

            case ReferenceTypes.race:
                return this.races;

            case ReferenceTypes.maritalStatus:
                return this.maritalStatuses;

            case ReferenceTypes.userStatus:
                return this.userStatuses;

            case ReferenceTypes.relationship:
                return this.relationships;

            case ReferenceTypes.relationshipStatus:
                return this.relationshipStatuses;

            case ReferenceTypes.relationGender:
                return this.relationGenders;

            case ReferenceTypes.guardian:
                return this.guardians;
            
            case ReferenceTypes.medicalCondition:
                return this.medicalConditions;

            case ReferenceTypes.rockkidzClass:
                return this.rockkidzClasses;

            case ReferenceTypes.rockKidzUserStatus:
                return this.rockKidzUserStatuses;
            
            case ReferenceTypes.emergencyContactStatus:
                return this.emergencyContactStatuses;

            default:
                throw new Error("Invalid reference type: " + type);
                
        }
    }
}

enum ReferenceTypes {
    country,
    countryCode,
    nationality,
    race,
    maritalStatus,
    userStatus,
    relationship,
    relationshipStatus,
    relationGender,
    guardian,
    medicalCondition,
    rockkidzClass,
    rockKidzUserStatus,
    emergencyContactStatus
}
